<template>
  <div class="coupon-used-modal max-width-page">
    <div class="profile-wrapper">
      <AccountModalHeader/>
    </div>
    <modal is-close class="has-margin-top-20"
    has-padding
    >
      <div class="is-flex justify-center align-items-center">
        <img src="@/assets/icon/x-circle.svg" v-if="error"/>
        <img src="@/assets/icon/check-circle.svg" v-else/>
      </div>
      <div class="has-text-center
      _fs-30
      has-text-centered
      has-text-weight-medium
      has-margin-top-20"
      :class="{
        'has-text-grey7c': error,
        'has-text-primary': !error
      }"
      >
        {{ error ? $t('couponUsedModal.fail') : $t('couponUsedModal.success')}}</div>
      <div class="_fs-16 has-text-black has-text-centered has-margin-top-10">{{ error ? $t('couponUsedModal.failDetail') : $t('couponUsedModal.successDetail')}}</div>
      <div class="has-margin-top-10 has-text-black40 has-text-centered _fs-14 has-text-weight-medium">
        {{$t('common.couponId', {couponId: error ? couponId: couponUsedId})}}
      </div>
      <div class="has-margin-top-10 has-text-black40 has-text-centered _fs-14 has-text-weight-medium" v-if="!error">
        {{$t('common.usedAt', {usedAt: usedAtParse})}}
      </div>
      <div class="has-margin-top-60 is-flex justify-center">
        <RoundButton @click="error ? $emit('tryAgain') : $router.replace({ name: 'HistoryCoupon' })">
          {{ error ? $t('couponUsedModal.btnTryAgain') : $t('couponUsedModal.btnHistory') }}
        </RoundButton>
      </div>
      <div class="has-margin-top-10 is-flex justify-center">
        <RoundButton type="text" @click="$router.replace({ name: 'Home' })">
          {{$t('couponUsedModal.btnBackToHome')}}
        </RoundButton>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from 'moment'
import Modal from '@/components/base/Modal.vue'
import RoundButton from '@/components/base/RoundButton.vue'
import AccountModalHeader from '@/components/AccountModalHeader.vue'
export default {
  props: {
    couponId: {
      type: String,
      required: true
    },
    couponUsedId: {
      type: String
    },
    usedAt: {
      type: String
    },
    error: {
      type: String
    }
  },
  components: {
    Modal,
    RoundButton,
    AccountModalHeader
  },
  computed: {
    usedAtParse () {
      if (this.usedAt) {
        return moment(this.usedAt).locale(this.$i18n.locale).format('DD MMM YYYY HH:mm')
      } else {
        return ''
      }
    }
  },
  methods: {
    close () {
      this.$parent.close()
      this.$emit('input', '')
      this.$emit('onClose')
    },
    onInputChange (newValue) {
      this.$emit('input', newValue)
    },
    submit () {
      this.resetScroll()
      this.$emit('submit')
    },
    resetScroll () {
      const y = document.documentElement.scrollTop
      window.scrollTo(0, y - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-used-modal {
  padding-top: 44px;
}
.profile-wrapper {
  padding: 0 14px;
}
</style>
