<template>
  <div class="coupon-detail-page">
    <div class="toolbar">
      <div class="is-flex justify-flex-start align-items-center" @click="$router.go(-1)">
        <img class="chevron" src="@/assets/icon/x.svg" height="20" width="20"/>
      </div>
      <div>
        <UsedBadge
          :used-time="myCoupon && myCoupon.used_time || 0"
          :limit-time="myCoupon && myCoupon.num_time || 0"
          :expired-date="myCoupon.expired_at"
        />
      </div>
    </div>
    <div class="content-section">
      <div class="img-product-wrapper">
        <div class="img-product">
          <img :src="coupon.url"
            @error="replaceByDefault"
          />
        </div>
      </div>
      <div class="content-wrapper">
        <div class="is-flex justify-space-between align-items-center">
          <div>
            <div class="has-text-white _fs-10 _fw-500 has-background-primary has-padding-left-5 has-padding-right-5" v-if="!isSpecial">{{$t('common.level', { level: memberLevel })}}</div>
            <div class="has-text-white _fs-10 _fw-500 has-background-primary has-padding-left-5 has-padding-right-5" v-else>{{coupon && coupon.collect_method || ''}}</div>
          </div>
          <!-- <div class="_fs-12 has-text-black40">{{$t('common.couponId', { couponId: coupon && coupon.code || ''})}}</div> -->
        </div>
        <div class="_fs-24 has-text-black has-margin-top-25">{{$i18n.locale === 'th' ? coupon && coupon.name || '' : coupon && coupon.name_en || ''}}</div>
        <div class="_fs-12 has-text-black40 has-margin-top-20">{{$t('couponDetail.expirationDate', {expiredAt: expiredAt || ''})}}</div>
        <div class="_fs-16 has-text-black has-margin-top-20"><p v-html="$i18n.locale === 'th' ? coupon && coupon.description || '' : coupon && coupon.description_en || ''" class="render"></p></div>

        <div class="_fs-12 has-text-black40 has-margin-top-15">{{$t('couponDetail.condition')}}</div>
        <div class="_fs-16 has-text-black has-margin-top-10"><p v-html="$i18n.locale === 'th' ? coupon && coupon.condition || '': coupon && coupon.condition_en || ''" class="render"></p></div>
        <div class="transaction-section" v-if="isHistory">
          <table class="transaction-table">
            <colgroup>
              <col style="width:30%">
              <col style="width:40%">
              <col style="width:30%">
            </colgroup>
            <tr>
              <th>{{$t('couponDetail.times')}}</th>
              <th>{{$t('couponDetail.usedDate')}}</th>
              <th>{{$t('couponDetail.usedTime')}}</th>
            </tr>
            <template v-if="transactions && transactions.length > 0">
              <tr v-for="(transaction, index) in transactions" :key="transaction.id">
                <td>{{ index + 1 }}</td>
                <td>{{ usedDateAt(transaction.used_at) }}</td>
                <td>{{ usedTimeAt(transaction.used_at) }}</td>
              </tr>
            </template>
          </table>
          <div class="has-text-centered is-flex align-items-center justify-center has-margin-top-10 _fs-12" v-if="transactions && transactions.length <= 0">{{$t('common.noData')}}</div>
        </div>
      </div>
    </div>
    <div class="is-flex justify-center action-section" v-if="!isHistory">
      <RoundButton @click="isDisabled ? () => {} : openAdminModal()" :disabled="isDisabled">{{$t('couponDetail.redeem')}}</RoundButton>
    </div>
    <b-modal
      scroll="clip"
      class="justify-center"
      :active.sync="isAdminModalActive"
      :can-cancel="false"
    >
      <AdminModal
        :coupon-id="coupon && coupon.code || ''"
        :admin-code="adminCode"
        @input="(newAdminCode) => { adminCode = newAdminCode }"
        @submit="redeem()"
      />
    </b-modal>
    <b-modal
      scroll="clip"
      class="justify-start"
      :active.sync="isCouponUsedModalActive"
      :can-cancel="false"
    >
      <CouponUsedModal
        @tryAgain="tryAgain()"
        :coupon-id="coupon && coupon.code || ''"
        :coupon-used-id="couponUsed && couponUsed.code"
        :used-at="couponUsed && couponUsed.used_at"
        :error="errorMessage"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import AdminModal from '@/components/modal/AdminModal.vue'
import CouponUsedModal from '@/components/modal/CouponUsedModal.vue'
import RoundButton from '@/components/base/RoundButton.vue'
import UsedBadge from '@/components/coupon/UsedBadge.vue'
import { mapActions, mapState } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
export default {
  data () {
    return {
      isAdminModalActive: false,
      isCouponUsedModalActive: false,
      adminCode: '',
      errorMessage: '',
      isSpecial: false,
      couponUsed: null
    }
  },
  components: {
    RoundButton,
    UsedBadge,
    AdminModal,
    CouponUsedModal
  },
  computed: {
    ...mapState('Coupon', ['myCoupon']),
    isHistory () {
      const mode = this.$route.query.mode
      if (mode === 'history') {
        return true
      }
      return false
    },
    coupon () {
      return (this.myCoupon && this.myCoupon.coupon) || {}
    },
    memberLevel () {
      return (this.myCoupon && this.myCoupon.member_card && this.myCoupon.member_card.name) || ''
    },
    expiredAt () {
      if (this.myCoupon && this.myCoupon.expired_at) {
        return moment(this.myCoupon.expired_at).locale(this.$i18n.locale).format('DD MMM YYYY HH:mm')
      } else {
        return ''
      }
    },
    isDisabled () {
      if (this.myCoupon === null) {
        return true
      }
      return (this.myCoupon.used_time || 0) >= (this.myCoupon.num_time || 0)
    },
    transactions () {
      return (this.coupon && this.coupon.transactions) || []
    }
  },
  methods: {
    ...mapActions({
      fetchMyCouponById: 'Coupon/fetchMyCouponById',
      redeemCoupon: 'Coupon/redeemCoupon',
      clearMyCoupon: 'Coupon/clearMyCoupon'
    }),
    ...mapWaitingActions('Coupon', {
      fetchMyCouponById: 'app loading',
      redeemCoupon: 'app loading'
    }),
    usedDateAt (usedAt) {
      return moment(usedAt).locale(this.$i18n.locale).format('DD MMM YYYY')
    },
    usedTimeAt (usedAt) {
      return moment(usedAt).locale(this.$i18n.locale).format('HH.mm')
    },
    async openAdminModal () {
      this.adminCode = ''
      this.isAdminModalActive = true
    },
    async fetchCouponDetail () {
      const id = this.$route.params.id.toString()
      await this.fetchMyCouponById({
        id: id
      })
    },
    async redeem () {
      const id = this.$route.params.id.toString()
      try {
        const couponUsed = await this.redeemCoupon({
          user_coupon: id,
          admin_code: this.adminCode
        })
        this.couponUsed = couponUsed
        console.log(couponUsed)
        this.errorMessage = ''
        this.isCouponUsedModalActive = true
        await this.fetchCouponDetail()
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const { data } = error.response
          if (data && data.admin_code) {
            this.errorMessage = 'admin_code not found'
          } else {
            this.errorMessage = data
          }
        } else {
          this.errorMessage = error.toString()
        }
        this.$buefy.toast.open({
          message: this.errorMessage,
          position: 'is-bottom',
          type: 'is-danger'
        })
        this.isCouponUsedModalActive = true
      } finally {
        this.isAdminModalActive = false
      }
    },
    async tryAgain () {
      this.isAdminModalActive = true
      this.isCouponUsedModalActive = false
      // await this.redeem()
    },
    replaceByDefault (e) {
      e.target.src = require('@/assets/img/coupon-default.png')
    }
  },
  created () {
    this.clearMyCoupon()
  },
  async mounted () {
    const collectMethod = this.$route.query.collectMethod || 'exclusive'
    this.isSpecial = collectMethod === 'special'
    await this.fetchCouponDetail()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.coupon-detail-page {
  min-height: 100vh;
  padding-top: 18px;
  /* position: relative; */
  .toolbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  .content-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding-bottom: 100px;
  }
  .img-product-wrapper {
    width: 100%;
    min-height: 200px;
    border-bottom: 1px solid $greyd0;
  }
  .img-product {
    background: $greyd0;
    width: 100%;
    padding-top: 56%;
    position: relative;
    background-size: cover;
    background-position: center top;
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content-wrapper {
    padding: 24px 20px;
    .render {
      word-wrap: break-word;
      /deep/ ol {
        list-style-position: inside;
      }
      /deep/ ul {
        list-style-position: inside;
        list-style-type: disc;
      }
    }
  }
  .action-section {
    position: fixed;
    bottom: 0;
    padding: 20px;
    width: 100%;
    background: white;
    z-index: 3;
  }
  .transaction-section {
    margin-top: 16px;
    .transaction-table {
      width: 100%;
      text-align: center;
      font-size: 1.2rem;
      th, td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 8px 0px;
        font-weight: 400;
      }
      th {
        opacity: 0.4;
      }
    }
  }
}
</style>
