<template>
  <div class="admin-modal max-width-page">
    <modal is-close class="has-margin-top-20"
    has-padding
    >
      <div class="has-text-center
      _fs-30
      has-text-primary
      has-text-centered
      has-text-weight-medium">
        {{$t('adminModal.progress')}}</div>
      <div class="_fs-16 has-text-black has-text-centered has-margin-top-10">{{$t('adminModal.progressDetail')}}</div>
      <div class="has-margin-top-20">
        <Input :placeholder="$t('adminModal.adminCode')"
          :value="adminCode"
          @input="onInputChange"
          @blur="resetScroll()"
          >
        </Input>
        <div
          style="font-size: 16px"
          v-if="error"
          class="error has-text-centered has-margin-top-5 has-text-weight-light">
          {{error}}
        </div>
      </div>
      <!-- <div class="has-margin-top-40 has-text-black40 has-text-centered _fs-14 has-text-weight-medium">
        {{$t('common.couponId', {couponId})}}
      </div> -->
      <div class="has-margin-top-10 is-flex justify-center">
        <RoundButton @click="submit()">
          {{$t('common.done')}}
        </RoundButton>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/base/Modal.vue'
import Input from '@/components/base/Input.vue'
import RoundButton from '@/components/base/RoundButton.vue'

export default {
  props: {
    adminCode: {
      type: String,
      required: true
    },
    couponId: {
      type: String,
      required: true
    },
    error: {
      type: String
    }
  },
  components: {
    Modal,
    Input,
    RoundButton
  },
  methods: {
    close () {
      this.$parent.close()
      this.$emit('input', '')
      this.$emit('onClose')
    },
    onInputChange (newValue) {
      this.$emit('input', newValue)
    },
    submit () {
      this.resetScroll()
      this.$emit('submit')
    },
    resetScroll () {
      const y = document.documentElement.scrollTop
      window.scrollTo(0, y - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-modal {
}
.profile-wrapper {
  padding: 0 14px;
}
</style>
